<template>
  <div>
    <CRow>
      <CCol sm="4" md="4"></CCol>
      <CCol lg="4">
        <span style="float: right; padding-left: 5px;">
          <CButton v-if="user.id == current_visit.kam_id && current_visit.status > 0" color="dark" size="sm" @click="openModal()" style="float: right; margin-left: 5px;">Validar Visita</CButton>
          <CButton v-if="after_page =='universe'" color="info" size="sm" @click="link('universo')" style="float: right; margin-left: 5px;">Ver Universo</CButton>
          <CButton v-if="after_page =='rute'" color="info" size="sm" @click="link('ruta')" style="float: right; margin-left: 5px;">Ver Ruta</CButton>
          <CButton color="info" size="sm" @click="link('home')" style="float: right;">Inicio</CButton>
          <span v-if="current_visit.status == 0"><br /><br />LA VISITA NO HA SIDO COMPLETADA</span>
        </span>    
        <h2>VISITA</h2>
        <span v-if="current_visit">
          <br />
          <b>{{current_visit.store_name}}</b><br />
          {{current_visit.branch_name}}
        </span>
        <br /><br /><br />
      </CCol>      
      <CCol sm="4" md="4"></CCol>
    </CRow>     
    <div>
      <CRow>
        <CCol sm="4" md="4"></CCol>
        <CCol sm="4" md="4">
          <CCard>
            <CCardHeader color="info">
              <span style="font-size: 12px;">
                <b>REPORTE DE LA VISITA</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  <strong>Antes</strong>
                </CCol>
                <CCol style="text-align: center; font-size: 12px;">
                  <strong> Después</strong>
                </CCol>
                <CCol style="text-align: center; font-size: 12px; margin-bottom: 10px;">
                  <strong>Evidencia</strong>
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <img src="" id="before" alt="" ref="beforeImage" style="width: 100px;" @click="showModalImage('before_image')">                  
                </CCol>
                <CCol>
                  <img src="" id="after" ref="afterImage" alt="" style="width: 100px;" @click="showModalImage('after_image')">
                </CCol>
                <CCol>
                  <img src="" id="problem" ref="problemImage" alt="" style="width: 100px;" @click="showModalImage('problem_image')">
                </CCol>
              </CRow>
              <CRow style="font-size: 12px;">
                <CCol>
                  <br />
                  <strong>Más Fotografías</strong>
                  <br /><br />
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <img src="" id="additional_1" ref="additional1Image" alt="" style="width: 100px;" @click="showModalImage('additional_1_image')">
                </CCol>
                <CCol>
                  <img src="" id="additional_2" ref="additional2Image" alt="" style="width: 100px;" @click="showModalImage('additional_2_image')">
                </CCol>
                <CCol>
                  <img src="" id="additional_3" ref="additional3Image" alt="" style="width: 100px;" @click="showModalImage('additional_3_image')">
                </CCol>
              </CRow>
              <CRow v-show="extras" style="text-align: center; font-size: 12px; margin-top: 20px;">
                <CCol>
                  <img src="" id="additional_4" ref="additional4Image" alt="" style="width: 100px;" @click="showModalImage('additional_4_image')">
                </CCol>
                <CCol>
                  <img src="" id="additional_5" ref="additional5Image" alt="" style="width: 100px;" @click="showModalImage('additional_5_image')">
                </CCol>
                <CCol>
                  <img src="" id="additional_6" ref="additional6Image" alt="" style="width: 100px;" @click="showModalImage('additional_6_image')">
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol>
                  <label for=""><strong>Comentarios</strong></label>
                  <p>{{ comments }}</p>
                </CCol>
              </CRow>         
              <hr/>
              <CRow v-if="has_promotion">
                <CCol md="12">
                  <label for=""><strong>Promoción: </strong></label>
                </CCol>
                <CCol md="12">
                  <CRow>
                    <CCol md="12">
                      <label for="">{{promotion}}</label>
                    </CCol>
                    <CCol md="12">
                      <div style="float:right; text-align: right;">
                        <span style="padding-bottom: 40px;">¿Tiene la promoción?</span><br />
                        <CButton v-if="status_promotion" disabled color="info" size="sm" style="margin-right: 10px; margin-top: 10px; width: 40px;">SI</CButton>
                        <CButton v-if="!status_promotion" disabled color="warning" size="sm" style="width: 40px;margin-top: 10px; ">NO</CButton>
                      </div>
                    </CCol>                                
                  </CRow>
                </CCol>
                <CCol md="12">
                  <label for=""><strong>Comentarios</strong></label>
                  <p>{{ comments_promotion }}</p>              
                </CCol>                
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="4" md="4"></CCol>
      </CRow>
      <CRow v-show="has_exhibitions">
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="dark">
              <span style="font-size: 12px;">
                <b>REPORTE EXHIBICIONES ADICIONALES</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  Imagen 1
                </CCol>
                <CCol style="text-align: center; font-size: 12px;">
                  Imagen 2
                </CCol>
                <CCol style="text-align: center; font-size: 12px; margin-bottom: 10px;">
                  Imagen 3
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <img src="" id="additional_exhibition_1" ref="additionalExhibition1Image" alt="" style="width: 100px;" @click="showModalImage('additional_exhibition')">
                </CCol>             
                <CCol>
                  <img src="" id="additional_exhibition_2" ref="additionalExhibition2Image" alt="" style="width: 100px;" @click="showModalImage('additional_exhibition')">
                </CCol>  
                <CCol>
                  <img src="" id="additional_exhibition_3" ref="additionalExhibition3Image" alt="" style="width: 100px;" @click="showModalImage('additional_exhibition')">
                </CCol>     
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-show="has_product_promotion">
        <CCol sm="12" md="6">
          <CCard>
            <CCardHeader color="dark">
              <span style="font-size: 12px;">
                <b>PRECIOS Y PROMOCIONES</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  <strong>Producto:</strong> {{product_exists.name}} <b>Codigo:</b> {{product_exists.code}}
                  <br>
                  <br>
                </CCol>               
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol style="text-align: center; font-size: 12px;">
                  Imagen
                </CCol>
                <CCol style="text-align: center; font-size: 12px;">
                  
                </CCol>
              </CRow>
              <CRow style="text-align: center; font-size: 12px;">
                <CCol>
                  <img src="" id="additional_promotion" ref="additionalPromotionImage" alt="" style="width: 100px;" @click="showModalImage('additional_promotion')">
                </CCol>             
                <CCol>
                  <span style="padding-bottom: 40px;">¿Tiene Precio?</span> {{(product_exists.has_price == 0) ? "No": (product_exists.has_price == 1) ? "Si": ""}}<br />
                </CCol>                  
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4" md="4"></CCol>
        <CCol sm="4" md="4">
          <CCard>
            <CCardHeader color="success">
              <span style="font-size: 12px;">
                <b>REPORTE DE LOS PRODUCTOS</b>
              </span>
            </CCardHeader>
            <CCardBody>
              <CRow v-for="(product, index) in products" :key="'product_'+index">
                <CCol sm="12" md="12" style="font-size: 12px;">
                  {{product.name}}<br />
                  <b>{{product.code}}</b>
                  <CRow>
                    <CCol>
                      <CInput
                        disabled
                        label="Físico"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.physical_stock"
                      />
                    </CCol>
                    <CCol>
                      <CInput
                        disabled
                        label="Lógico"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.logic_stock"
                      />
                    </CCol>
                    <CCol>
                      <CInput
                        disabled
                        label="Frentes"
                        size="sm"
                        style="width: 80px; float: right;"
                        v-model="product.front_stock"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <br /><CTextarea label="" disabled v-model="product.comments"></CTextarea>
                    </CCol>
                  </CRow>
                  <hr />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="4" md="4"></CCol>
      </CRow>
    </div>
    <ConfirmModal ref="confirmModal" @confirm="checkout" @reject="reject"></ConfirmModal>
    <ImageModal ref="imageModal"></ImageModal>
  </div>
</template>
<script>

import store from '../../store'
import router from '../../router/index'
import ws from '../../services/kam';
import moment from 'moment';
import ConfirmModal from './modals/ConfirmModal.vue';
import ImageModal from './modals/ImageModal.vue';

export default {
  name: 'Visit',
  components: {
    ConfirmModal,
    ImageModal,
  },
  data () {
    return {
      visit_id: 0,
      current_visit: false,
      comments: '',
      products: [],
      after_page: "",
      images: [],
      promotion: "",
      has_promotion: false,
      status_promotion: -1,
      comments_promotion: "",
      has_exhibitions:0,
      has_product_promotion: 0,
      product_exists:{
        name:"",
        code:"",
        has_price: -1,
        id:"",
      },
      user: false,
      extras: false
    }
  },
  mounted: async function() {
    this.loading();
      this.visit_id = await this.$route.params.id;
      this.startVisit(this.visit_id);
      this.after_page = localStorage.getItem("after_page");
      this.user = JSON.parse(localStorage.getItem("user"));
    this.loaded();
  },
  methods: {
    async checkout (comments) {
      this.loading();
      let response = await ws.setCheckOut(this.visit_id,comments);
      localStorage.removeItem("last_login");

      if(response.type == "success"){
        this.startVisit(this.visit_id);
      }
      this.loaded();
      this.showToast(response.type, response.message);
    },
    async reject (comments) {
      this.loading();
      let response = await ws.setReject(this.visit_id,comments);
      localStorage.removeItem("last_login");

      if(response.type == "success"){
        this.startVisit(this.visit_id);
      }
      this.loaded();
      this.showToast(response.type, response.message);
    },
    async openModal () {
      this.$refs.confirmModal.show(this.current_visit.validation_comments, this.current_visit.status);
    },
    async startVisit (visit_id) {
      this.loading();

      let response = await ws.getVisit(visit_id); 

      if(response.type == "success"){
        let data = response.data;

        this.images = response.images;
        let images = response.images;

        this.current_visit = data;

        this.comments = this.current_visit.comments;
        this.promotion = this.current_visit.promotion;
        this.has_promotion = this.current_visit.has_promotion;
        this.status_promotion = this.current_visit.status_promotion;
        this.comments_promotion = this.current_visit.comments_promotion;

        this.product_promotion_already_exists = (this.current_visit.id_additional_promotion > 0) ? 1 : 0;
        this.product_exists.has_price = this.current_visit.status_price_additional_promotion;
        this.product_exists.id = this.current_visit.id_additional_promotion;
        this.product_exists.name = this.current_visit.promotion_product_name;
        this.product_exists.code = this.current_visit.promotion_product_code;

        this.has_exhibitions = this.current_visit.has_exhibitions;
        this.has_product_promotion = this.current_visit.has_product_promotion;

        let no_image_url = "https://s3.us-west-2.amazonaws.com/tumarketing3.com/no-image.png";
        if(images.before_image !== undefined){
          this.$refs.beforeImage.src = images.before_image.url;
        }else{
          this.$refs.beforeImage.src = no_image_url;
        }

        if(images.after_image !== undefined){
          this.$refs.afterImage.src = images.after_image.url;
        }else{
          this.$refs.afterImage.src = no_image_url;
        }

        if(images.problem_image !== undefined){
          this.$refs.problemImage.src = images.problem_image.url;
        }else{
          this.$refs.problemImage.src = no_image_url;
        }

        if(images.additional_1_image !== undefined){
          this.$refs.additional1Image.src = images.additional_1_image.url;
        }else{
          this.$refs.additional1Image.src = no_image_url;
        }

        if(images.additional_2_image !== undefined){
          this.$refs.additional2Image.src = images.additional_2_image.url;
        }else{
          this.$refs.additional2Image.src = no_image_url;
        }

        if(images.additional_3_image !== undefined){
          this.$refs.additional3Image.src = images.additional_3_image.url;
        }else{
          this.$refs.additional3Image.src = no_image_url;
        }

        if(images.additional_4_image !== undefined){
          this.extras = true;
          this.$refs.additional4Image.src = images.additional_4_image.url;
        }else{
          this.$refs.additional4Image.src = no_image_url;
        }

        if(images.additional_5_image !== undefined){
          this.extras = true;
          this.$refs.additional5Image.src = images.additional_5_image.url;
        }else{
          this.$refs.additional5Image.src = no_image_url;
        }

        if(images.additional_6_image !== undefined){
          this.extras = true;
          this.$refs.additional6Image.src = images.additional_6_image.url;
        }else{
          this.$refs.additional6Image.src = no_image_url;
        }

        if(images.additional_exhibition_1 !== undefined){
          this.$refs.additionalExhibition1Image.src = images.additional_exhibition_1.url;
        }else{
          this.$refs.additionalExhibition1Image.src = no_image_url;
        }
        if(images.additional_exhibition_2 !== undefined){
          this.$refs.additionalExhibition2Image.src = images.additional_exhibition_2.url;
        }else{
          this.$refs.additionalExhibition2Image.src = no_image_url;
        }
        if(images.additional_exhibition_3 !== undefined){
          this.$refs.additionalExhibition3Image.src = images.additional_exhibition_3.url;
        }else{
          this.$refs.additionalExhibition3Image.src = no_image_url;
        }
        if(images.additional_promotion !== undefined){
          this.$refs.additionalPromotionImage.src = images.additional_promotion.url;
        }else{
          this.$refs.additionalPromotionImage.src = no_image_url;
        }

        let response_products = await ws.getProductsVisit(visit_id,data.product_ids); 
        this.products = [];
        if(response_products.type == "success"){          
          this.products = response_products.data;
        }

        this.loaded();
      }else{
        this.showToast("error", "No se encontro la visita")
      }
    },
    async showModalImage(type){
      switch (type) {
        case "before_image":
            if(this.images.before_image !== undefined){
              this.$refs.imageModal.show(this.images.before_image.url.replace("thumb_",""));
            }            
          break;
        case "after_image":     
            if(this.images.after_image !== undefined){
              this.$refs.imageModal.show(this.images.after_image.url.replace("thumb_",""));
            }             
          break;
        case "problem_image":          
            if(this.images.problem_image !== undefined){
              this.$refs.imageModal.show(this.images.problem_image.url.replace("thumb_",""));
            }        
          break;
        case "additional_1_image":          
            if(this.images.additional_1_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_1_image.url.replace("thumb_",""));
            }
          break;
        case "additional_2_image":          
            if(this.images.additional_2_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_2_image.url.replace("thumb_",""));
            }
          break;
        case "additional_3_image":
            if(this.images.additional_3_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_3_image.url.replace("thumb_",""));
            }
          break;   
        case "additional_4_image":          
            if(this.images.additional_4_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_4_image.url.replace("thumb_",""));
            }
          break;
        case "additional_5_image":          
            if(this.images.additional_5_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_5_image.url.replace("thumb_",""));
            }
          break;
        case "additional_6_image":
            if(this.images.additional_6_image !== undefined){
              this.$refs.imageModal.show(this.images.additional_6_image.url.replace("thumb_",""));
            }
          break;         
        case "additional_exhibition_1":
            if(this.images.additional_exhibition_1 !== undefined){
              this.$refs.imageModal.show(this.images.additional_exhibition_1.url.replace("thumb_",""));
            }
        case "additional_exhibition_2":
            if(this.images.additional_exhibition_2 !== undefined){
              this.$refs.imageModal.show(this.images.additional_exhibition_2.url.replace("thumb_",""));
            }
        case "additional_exhibition_3":
            if(this.images.additional_exhibition_3 !== undefined){
              this.$refs.imageModal.show(this.images.additional_exhibition_3.url.replace("thumb_",""));
            }
        case "additional_promotion":
            if(this.images.additional_promotion !== undefined){
              this.$refs.imageModal.show(this.images.additional_promotion.url.replace("thumb_",""));
            }       
          break;                                 
      }            
    }, 
    link(type){
      switch (type) {
        case "home":
            this.$router.push('/welcome');
          break;
        case "universo":
            this.$router.push('/universo/'+this.current_visit.customer_id);
          break;
        case "ruta":
            this.$router.push('/ruta/'+this.current_visit.collaborator_id);
          break;          
        default:
          break;
      }      
    },    
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
