<template>
    <CModal title="Confirmar Visita" :color="colorModal" :show.sync="confirmModal">
        <p style="font-weight: bold;">¿Deseas marcar como revisada esta visita?</p>
        <p style="font-weight: bold; color: red" v-if="!is_completed">Esta visita no ha sido completada (no tiene checkout) por el promotor, si la aceptas esta se marcará como completada.</p>
        <CTextarea label="Comentarios" v-model="comments"></CTextarea>
        <template #header>
          <h5 class="modal-title">Confirmar Visita</h5>
          <CButtonClose @click="confirmModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="confirmModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="rejectModal" color="danger">Rechazar</CButton>
          <CButton @click="confirmationModal" color="success">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'

export default {
  name: 'ConfirmModal',
  props: {

  },
  data () {
    return {
      confirmModal: false,
      colorModal: 'warning',
      comments: "",
      is_completed: false
    }
  },
  methods: {
    show (comments, is_completed) {
      this.confirmModal = true;
      this.is_completed = is_completed;
      this.comments = comments;
    },
    confirmationModal () {
      if(this.comments == "" || this.comments.length < 20){
        this.showToast("error","El comentario debe contener mas de 20 caracteres")
        return false;
      }
      this.confirmModal = false;
      this.$emit("confirm",this.comments);
    },
    rejectModal () {
      if(this.comments == "" || this.comments.length < 20){
        this.showToast("error","El comentario debe contener mas de 20 caracteres")
        return false;
      }
      this.confirmModal = false;
      this.$emit("reject",this.comments);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>