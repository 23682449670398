<template>
    <CModal :color="colorModal" :show.sync="confirmModal" size="lg">
      <CRow>
        <CCol style="text-align: center;">
          <img :src="url" alt="">
        </CCol>
      </CRow>            
      <template #header>
      </template>
        <template #footer>
          <CButton @click="confirmModal = false">Cerrar Ventana</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'

export default {
  name: 'ConfirmModal',
  props: {

  },
  data () {
    return {
      confirmModal: false,
      colorModal: 'info',
      comments: "",
      url:""
    }
  },
  methods: {
    show (url) {
      this.loading();
      this.url = url;
      this.confirmModal = true;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>